import React from 'react'

const Footer = () => {
  return (
 <footer>
   all @copyrights Reserved
 </footer>
  )
}

export default Footer